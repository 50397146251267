.tag-component {
    align-items: center;
    margin: 0 10px;
    height: 100%;
    label {
        margin-right: 8px;
    }
}

.button-container {
    overflow: hidden;
    border-radius: 18px;
    border: 1px dashed #DFE6E9;
}

.button-container > button {
    padding: 0px !important;
}

.tags-container {
    align-items: center;
    display: flex;
    min-height: 32px;
    margin-right: 4px;
}

.chip {
    span {
        padding-right: 8px;
        padding-left: 8px;
        margin-right: 1px;
    }
}

.item {
    margin: 1px 2px 0 2px !important;
}

.custom-menu {
    padding: 0;
    margin: 0;
}
