$sidebar-bg-color: #151e33 !default;
$sidebar-color: #a8b1ca !default;
$highlight-color: #fff !default;
$submenu-bg-color: #1b2641 !default;
$submenu-bg-color-collapsed: #1b2641 !default;
$icon-bg-color: transparent default;
$sidebar-width: 220px !default;
$sidebar-collapsed-width: 70px !default;
$icon-size: 20px !default;
$submenu-indent: 24px !default;
$font-size: 14px;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';


.pro-sidebar {
    .pro-menu{
        .pro-menu-item{
            font-size: 14px;
            min-height: 40px;

            .pro-inner-item{
                padding: 12px 20px;
            }
        }

        .pro-menu-item.pro-sub-menu{
            .pro-inner-list-item{
                .pro-inner-item{
                    padding: 12px 20px 12px 10px
                }
            }
        }
    }
    .pro-sidebar-layout{
        overflow-y: hidden !important;
        .pro-sidebar-content{
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: none;
            -ms-overflow-style: none;
            .pro-menu{
                padding-top: 0px !important;
                padding-bottom: 0px !important;
            }
        }
        .pro-sidebar-content::-webkit-scrollbar{
            display: none;
        }
    }
}