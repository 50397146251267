
.picture-car-read {
    width: 240px;
    border: 1px solid #dfe6e9;
    border-radius: 5px;
    margin: 7px;
    position: relative;
    z-index: 9999;
    overflow: hidden;
    height: 180px;

    img {
        width: 100%;
        max-height: 180px;
        margin: auto 0;
    }

    .action-container {
        -moz-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        opacity: 0;
        z-index: -1;
    }

    .show-image {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 100%;
    }

    &:hover .action-container {
        opacity: 1;
        z-index: 1;
    }

    &:hover .action-container .picture-bg {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        background: #000;
        opacity: 0.7;
    }

    .delete,
    .search {
        -moz-transition: all 0.5s;
        -webkit-transition: all 0s;
        transition: all 0.5s;
        vertical-align: middle;
    }

    &:hover .delete,
    &:hover .search {
        margin: 0 3px;
        padding: 8px;
        color: #fff;
        cursor: pointer;
        font-size: 30px;
        border: 1px solid #fff;
        border-radius: 35px;
        z-index: 2;
    }
}
