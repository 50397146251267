.ReactVirtualized__Table__headerRow{
  text-transform: none;
  font-size: 12px;
  background-color: #cacfe4 !important;
  color: #707090;
}

.ReactVirtualized__Table__row:nth-child(even){
  background-color: whitesmoke;
}

.ReactVirtualized__Table__row:hover{
  background-color: #7080b936 !important;
}

.ReactVirtualized__Table__rowColumn,
.ReactVirtualized__Table__rowColumn > span{
  font-size: 12px;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.ReactVirtualized__Table__headerTruncatedText {
  flex: auto;
}

.DragHandle {
  flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: #0085ff;
}

.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: #0b6fcc;
  z-index: 3;
}

.DragHandleIcon {
  position: absolute;
  right: -3px;
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff,
}