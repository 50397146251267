// Text Color
.text-primary {
    color: $primary;
}


// Text Size
.font-16 {
    font-size: 16px;
}


// Email 
.im {
    display: block;
    color: #500050;
}