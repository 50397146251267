.jodit-container:not(.jodit_inline) {
    border: none;
    border-radius: 0;
}

.sms-notification {
    background-color: #3c404c;
    border-radius: 4px;
    color: #fff;
    min-height: 56px;
    max-height: 150px;
    overflow: hidden;
    padding: 14px;
    position: relative;
    width: 100%;
    .Toastify__toast-body {
        width: 100%;
    }
    .Toastify__close-button--default {
        opacity: 1;
        color: #fff;
    }
}

.Toastify__toast-container--top-right {
    top: 2.7em;
}