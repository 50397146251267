
.d-flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.d-flex-column-equal {
    display: flex;
    flex-direction: column;
    & > * {
        flex: 1;
    }
}

.flex-grow-1 {
    flex-grow: 1;
}

.d-flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.d-flex-column-align-space-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.d-flex-center {
    display: flex;
    align-items: center;
}

.d-flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.d-flex-center-align-start {
    display: flex;
    align-items: flex-start
}

.d-flex-center-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.d-flex-justify-end {
    display: flex;
    justify-content: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.d-flex-justify-space-around {
    display: flex;
    justify-content: space-around;
}

.d-flex-justify-end-important {
    display: flex !important;
    justify-content: flex-end !important;
}

.d-flex-justify-center-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex-justify-start-align-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.d-flex-justify-end-align-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.d-flex-justify-end-align-end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.d-flex-justify-start-align-center1 {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.d-flex-direction-column-align-baseline {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}


.d-flex-direction-column-justify-flex-end {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.d-flex-align-baseline-space-between {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.d-flex-align-baseline {
    display: flex;
    align-items: baseline;
}