.fc table .fc-col-header {
    height: 40px;
}
.fc-col-header-cell {
    background-color: #F1F1F1;
}

.fc th .fc-col-header-cell {
    vertical-align: middle;
}

.fc .fc-event:focus:after {
    background: initial;
}

.fc .fc-daygrid-event {
    cursor: pointer;
}

.fc-h-event {
    border: none;
    padding: 4px;
    border-radius: 6px;
    margin-bottom: 4px;
}

.fc-event-title {
    font-weight: 600;
}